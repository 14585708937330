@mixin font-face($font-name, $file-name) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.eot');
    src: url($file-name + '.eot?#iefix') format('embedded-opentype'),
    url($file-name + '.woff') format('woff'),
    url($file-name + '.woff2') format('woff2'),
    url($file-name + '.ttf') format('truetype'),
    url($file-name + '.svg##{$font-name}') format('svg');
  }
}

@include font-face('K2D-Medium', '../../fonts/K2D-Medium');
@include font-face('K2D-Bold', '../../fonts/K2D-Bold');
@include font-face('K2D-ExtraBold', '../../fonts/K2D-Bold');
